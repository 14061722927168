.login-form{
    width: 480px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
}

#components-form-demo-normal-login {
    max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
    float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}
#components-form-demo-normal-login .login-form-button {
    width: 100%;
}